exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalendarium-js": () => import("./../../../src/pages/kalendarium.js" /* webpackChunkName: "component---src-pages-kalendarium-js" */),
  "component---src-pages-om-skolan-personal-js": () => import("./../../../src/pages/om-skolan/personal.js" /* webpackChunkName: "component---src-pages-om-skolan-personal-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-profile-page-template-js": () => import("./../../../src/templates/profile-page-template.js" /* webpackChunkName: "component---src-templates-profile-page-template-js" */)
}

